<template>

    <div class='list_content'>
        <h2 style="text-align: center;">excel导入表单( 只支持xslx后缀 )</h2>
        <div style="padding:50px">
            <el-upload class="upload-demo" drag action="http://106.52.244.38:9002/form/excel/add" multiple
                :headers="uploaHeaders" :data="uploadData" :on-success="uploadSUcc">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">只能上传xslx文件，且不能直接改后缀名</div>
            </el-upload>
        </div>
    </div>
</template>

<script>
import LeftMenu from '@/components/left.vue'
import HeaderTop from '@/components/top.vue'
import Footer from '@/components/footer.vue'

export default {
    name: 'ExcelAddView',
    // 注册组件
    components: {
        LeftMenu,
        HeaderTop,
        Footer
    },
    props: {},
    // data() 为变量赋值等
    data() {
        return {
            sec_user: {},
            uploaHeaders: {
                // "Content-Type": "multipart/form-data"
            },
            uploadData: {
                user_id: 0,
            }
        }
    },

    created() {
        // 从参数获取userid
        this.form_id = this.$route.query.form_id
        // 从缓存获取 登录用户
        this.sec_user = JSON.parse(sessionStorage.getItem('sec_user'))
        this.uploadData.user_id = this.sec_user.user_id
    },

    methods: {
        uploadSUcc(response, file, fileList) {
            alert(response.Msg)
        },
    }
}

</script>

<style scoped="scoped">
/*大框体样式*/
.el-main {
    height: calc(100vh - 120px);
}

.list_content {
    max-width: 800px;
}

.el-form-item__label {
    max-width: 200px !important;
}

.upload-demo {
    text-align: center;
}
</style>
